<dx-popup
	[width]="480"
	[height]="250"
	[showTitle]="true"
	[title]="title"
	[dragEnabled]="true"
	[hideOnOutsideClick]="hideOnOutsideClick"
	[showCloseButton]="false"
	[(visible)]="showResetConfirmationPopup"
>
	<div *dxTemplate="let data of 'content'">
		<div class="popup-container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<span>{{ message }}</span>
				</div>
			</div>
			<div class="popup-btn-container">
				<button *ngIf="!showCloseButton" mat-raised-button class="btn-performance-save" (click)="onConfirmation()">Yes</button>
				<button *ngIf="!showCloseButton" class="btn-performance-cancel" style="margin-left: 5px" mat-raised-button (click)="hidePopup()">No</button>
				<button *ngIf="showCloseButton" mat-raised-button class="btn-performance-save" (click)="onConfirmation()">Close</button>
			</div>
		</div>
	</div>
</dx-popup>
